<template>
  <div class="page bg-white" id="reorganize">
    <div class="pageHeight">
      <el-steps v-if="butType != 1" :active="step" align-center style="margin-bottom: 20px">
        <el-step title="基本信息"></el-step>
        <el-step title="入库藏品"></el-step>
        <el-step title="预览"></el-step>
      </el-steps>

      <div v-show="step == 0 || step == 2">
        <div class="minTitle">基本信息</div>
        <el-form :model="inputForm" ref="inputForm" :rules="rules" label-width="120px"
                 size="small">
          <el-row>
            <el-col :span="11">
              <el-form-item label="入库原因" prop="reason">
                <el-select
                    :disabled="true"
                    v-model="inputForm.reason"
                    clearable
                    placeholder="请选择入库原因"
                    style="width: 100%"
                >
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('outbound_reason')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="入库申请人" prop="applicantName">
                <el-input
                    v-model.trim="inputForm.applicantName"
                    placeholder="请输入入库申请人(限50字)"
                    maxlength="50"
                    :disabled="true"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="具体原因" prop="specificReasons">
                <el-input type="textarea" rows="1" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.specificReasons" maxlength="50"
                          placeholder="请输入具体原因(限50字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="入库时间" prop="operationDate">
                <el-date-picker
                    v-model="inputForm.operationDate"
                    :disabled="inputType"
                    type="date"
                    clearable
                    style="width: 100%"
                    value-format="yyyy-MM-dd"
                    :picker-options="outBoundSet"
                    placeholder="选择入库时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="备注说明" prop="remark">
                <el-input type="textarea" rows="4" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.remark" maxlength="500"
                          placeholder="详细描述藏品出库的详细原因以及备注(限500字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div v-show="step == 1 || step == 2">
        <div v-if="step != 2 && butType != 3">
          <el-button size="small" type="primary" @click="addDataList(1,generalList)" icon="el-icon-plus">
            总账藏品
          </el-button>
          <el-button size="small" type="primary" @click="addDataList(2,auxiliaryList)" icon="el-icon-plus">
            辅助帐藏品
          </el-button>
        </div>

        <div class="flex_b_c" v-if="butType == 1 && inputForm.applicationState != 0 && inputForm.applicationState != 1">
          <div class="minTitle">藏品清单</div>
          <div class="tabsCls">
            <el-tabs size="small" v-model="selectTab" @tab-click="handleClick">
              <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                           :name="item.value"></el-tab-pane>
            </el-tabs>
          </div>
        </div>

        <div class="flex_b_c">
          <div>
            <div class="minTitle">总账藏品清单</div>
            <div v-if="butType != 3" style="margin-left: 25px">已选{{ generalList.length }}件/套 藏品</div>
            <div v-else style="margin-left: 25px"><span v-if="step != 2">合计{{ generalList.length }}件/套 藏品</span>
              <span> 已选择 {{ selectGeneralList.length }}件/套  藏品</span></div>
          </div>
          <div v-show="step != 2 && butType != 3">
            <el-button icon="el-icon-delete" size="small" type="danger"
                       @click="deleteListData(generalList,selectGeneralList,1)">批量删除
            </el-button>
          </div>
        </div>
        <el-table
            :data="generalList"
            size="small"
            style="padding-left: 20px"
            height="300px"
            ref="multipleTable1"
            class="table"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            @selection-change="selectionChangeGenera"
        >
          <el-table-column :reserve-selection="true" type="selection" :selectable="canBeSelect" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="name" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.collectionData.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.collectionData.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.collectionData.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="collectionName" label="藏品名称"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.collectionName }}
            </template>
          </el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.helpNum }}
            </template>
          </el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.generalNum }}
            </template>
          </el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.oneTypeName }}
            </template>
          </el-table-column>
          <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionData.collectionLevel) }}
            </template>
          </el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.identified == 0 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column prop="collectionType" label="藏品类型" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getTypeList(scope.row.collectionData.collectionType) }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.integrityInfo }}
            </template>
          </el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.integrity }}
            </template>
          </el-table-column>
          <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $dictUtils.getDictLabel("cStore_state", scope.row.collectionData.cStoreState, '-') }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button v-show="step != 2 && butType != 3" style="margin-bottom: 10px" size="mini" type="text"
                         @click="deleteListData(generalList,[scope.row],1,scope.$index)">
                删除
              </el-button>
              <el-button v-if="butType == 1" style="margin-bottom: 10px" size="mini" type="text"
                         @click="detail(scope.row,1)">
                藏品详情
              </el-button>
              <el-button v-if="butType == 1 && scope.row.storeState == 0 && longSwitch == 1" style="margin-bottom: 10px"
                         size="mini" type="text"
                         @click="application(scope.row,1)">
                入库申请
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="flex_b_c">
          <div>
            <div class="minTitle">辅助账藏品清单</div>
            <div v-if="butType != 3" style="margin-left: 25px">已选{{ auxiliaryList.length }}件/套 藏品</div>
            <div v-else style="margin-left: 25px"><span v-if="step != 2">合计{{ auxiliaryList.length }}件/套 藏品</span>
              <span> 已选择 {{ selectAuxiliaryList.length }}件/套  藏品</span></div>
          </div>
          <div v-show="step != 2 && butType != 3">
            <el-button icon="el-icon-delete" size="small" type="danger"
                       @click="deleteListData(auxiliaryList,selectAuxiliaryList,2)">
              批量删除
            </el-button>
          </div>
        </div>
        <el-table
            :data="auxiliaryList"
            size="small"
            style="padding-left: 20px"
            height="300px"
            class="table"
            ref="multipleTable2"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            @selection-change="selectionChangeAuxiliary"
        >
          <el-table-column :reserve-selection="true" type="selection" :selectable="canBeSelect" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="name" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.collectionData.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.collectionData.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.collectionData.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="collectionName" label="藏品名称"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.collectionName }}
            </template>
          </el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.helpNum }}
            </template>
          </el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.generalNum }}
            </template>
          </el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.oneTypeName }}
            </template>
          </el-table-column>
          <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionData.collectionLevel) }}
            </template>
          </el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.identified == 0 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column prop="collectionType" label="藏品类型" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getTypeList(scope.row.collectionData.collectionType) }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.integrityInfo }}
            </template>
          </el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.integrity }}
            </template>
          </el-table-column>
          <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $dictUtils.getDictLabel("cStore_state", scope.row.collectionData.cStoreState, '-') }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button v-show="step != 2 && butType != 3" style="margin-bottom: 10px" size="mini" type="text"
                         @click="deleteListData(auxiliaryList,[scope.row],2,scope.$index)">
                删除
              </el-button>
              <el-button v-if="butType == 1" style="margin-bottom: 10px" size="mini" type="text"
                         @click="detail(scope.row,2)">
                藏品详情
              </el-button>
              <el-button v-if="butType == 1 && scope.row.storeState == 0 && longSwitch == 1" style="margin-bottom: 10px"
                         size="mini" type="text"
                         @click="application(scope.row,1)">
                入库申请
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div v-show="step == 0 || step == 2" style="margin-top: 10px">
      <div class="text_center" v-if="step != 2">
        <el-button size="small" @click="returnPage(1)">取消</el-button>
        <el-button size="small" type="primary" @click="nextStep(0)">下一步</el-button>
      </div>
    </div>
    <div v-show="step == 1 || step == 2" style="margin-top: 10px">
      <div class="text_center" v-if="step == 1">
        <el-button size="small" @click="returnPage(1)">取消</el-button>
        <el-button size="small" type="primary" @click="nextStep(1)">上一步</el-button>
        <el-button size="small" type="primary" @click="nextStep(0)">预览</el-button>
      </div>

      <div class="text_center" v-else-if="step == 2 && butType != 1 && butType != 4 && butType != 5">
        <el-button size="small" @click="returnPage(1)">取消</el-button>
        <el-button size="small" type="primary" @click="nextStep(1)">上一步</el-button>
        <el-button size="small" type="primary" @click="saveAsDraft(1)" v-no-more-click>提交</el-button>
      </div>
      <div class="text_center" v-else>
        <el-button v-if="butType == 1 || butType == 4 || butType == 5" size="small" type="primary"
                   @click="switchData(1)">上一条
        </el-button>
        <el-button v-if="butType == 1 || butType == 4 || butType == 5" size="small" type="primary"
                   @click="switchData(0)">下一条
        </el-button>
        <el-button size="small" @click="returnPage(1)">关闭</el-button>
        <el-button v-if="butType == 1 && longSwitch == 1 && this.inputForm.reviewState == 2" size="small" type="primary"
                   @click="warehousingApplication()">入库申请
        </el-button>
        <el-button size="small" v-if="butType == 4 && this.inputForm.reviewState == 0" type="primary"
                   @click="through(3)">通过
        </el-button>
        <el-button size="small" v-if="butType == 4 && this.inputForm.reviewState == 0" type="danger"
                   @click="through(2)">驳回
        </el-button>
      </div>
    </div>
    <collList ref="collList"></collList>
    <warehousing-pop ref="warehousingPop" @refresh="refresh()"></warehousing-pop>
    <reviewSuggestions ref="suggestions" @updataStatus="getRowDetail()"></reviewSuggestions>
  </div>
</template>

<script>
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";
import collList from "@/views/modules/collection/business/outBound/collList.vue";
import WarehousingPop from "@/views/modules/collection/business/warehousing/warehousingPop.vue";

export default {
  name: "wareDetail",
  components: {WarehousingPop, reviewSuggestions, collList},
  data() {
    return {
      id: '',
      step: 0,
      inputForm: {
        reason: '0',
        applicant: '',
        applicantName: '',
        specificReasons: '',
        operationDate: '',
        remark: '',
      },
      rules: {
        applicantName: [
          {required: true, message: '请输入入库申请人', trigger: 'blur'}
        ],
        specificReasons: [
          {required: true, message: '请输入具体原因', trigger: 'blur'}
        ],
        operationDate: [
          {required: true, message: '请选择入库时间', trigger: 'change'}
        ],
      },

      generalList: [],
      generalList2: [],
      selectGeneralList: [],

      auxiliaryList: [],
      auxiliaryList2: [],
      selectAuxiliaryList: [],

      immovableList: [],
      selectImmovableList: [],

      registrationSearch: {},

      inputType: false,
      inputType2: false,
      returnPageStatus: '',
      butType: "", //0新增 1详情 2修改 3一键入库
      switch: '',//区分上下条接口
      longSwitch: '',
      leaveList: [],
      typeList: [],
      storeStateList: [],

      outBoundSet: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },

      applicationStateList: [
        {
          name: '申请中',
          value: '0',
        },
        {
          name: '申请驳回',
          value: '1',
        },
        {
          name: '申请通过',
          value: '2',
        },
        {
          name: '已结束',
          value: '3',
        },
      ],
      selectTab: '99',
      tabsList: [
        {
          name: '全部',
          value: '99',
        },
        {
          name: '待入库',
          value: '0',
        },
        {
          name: '入库中',
          value: '1',
        },
        {
          name: '已入库',
          value: '2',
        },
        {
          name: '已注销',
          value: '4',
        },
      ],
    }
  },

  mounted() {
    this.id = this.$route.query.id
    this.switch = this.$route.query.switch
    if (!this.id) {
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      this.inputForm.applicant = userInfo.id
      this.inputForm.applicantName = userInfo.name
    } else {
      this.getRowDetail()
    }

    this.butType = this.$route.query.butType
    this.longSwitch = this.$route.query.switch
    if (this.butType == 1) {
      this.inputType = true
      this.inputType2 = true
      this.step = 2
    }
    if (this.butType == 3) {
      this.inputType2 = true
    }
    this.setPageTitle()

    this.getAllState()
  },

  methods: {
    //0征集藏品 1总账藏品 2辅助帐藏品 3不可移动文物
    addDataList(num, data) {
      this.$refs.collList.init(num, data, 0)
    },

    selectionChangeGenera(val) {
      this.selectGeneralList = val
    },

    selectionChangeAuxiliary(val) {
      this.selectAuxiliaryList = val
    },

    selectionChangeImmovable(val) {
      this.selectImmovableList = val
    },

    //删除
    deleteListData(data, selectData, num, indexrt) {
      if (indexrt == 0 || indexrt) {
        this.$confirm(`点击确定后，将会从列表中删除您选中的藏品数据?`, '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          data.splice(indexrt, 1)
          this.$refs['multipleTable' + num].clearSelection()
        })
      } else {
        if (selectData.length == 0) {
          this.$message.warning('请至少选择一条数据')
          return
        }
        this.$confirm(`点击确定后，将会从列表中删除您选中的藏品数据?`, '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          selectData.forEach(item2 => {
            data.forEach((item1, index) => {
              if (item1.collectionData.id == item2.collectionData.id) {
                data.splice(index, 1)
              }
            })
          })
          this.$refs['multipleTable' + num].clearSelection()
        })
      }
    },

    //详情
    detail(row, num) {
      this.$router.push({
        path: '/collection/accounts/addCollection',
        query: {
          butType: 3,
          id: row.snapshotId,
          archivesBasicDataId: row.collectionData.archivesBasicDataId,
          typePage: 'InOroutStorage',
          see: 1
        }
      })
    },

    //入库申请
    application(row) {
      this.$refs.warehousingPop.init(row, this.inputForm)
    },

    getAllState() {
      this.$axios(this.api.collection.listLevelSelect).then(data => {
        if (data.status) {
          this.leaveList = data.data
        }
      })
      this.$axios(this.api.collection.listSelect).then(data => {
        if (data.status) {
          this.typeList = data.data
        }
      })
      this.$axios(this.api.collection.listOutBount, {}, 'get').then(data => {
        if (data.status) {
          this.storeStateList = data.data
        }
      })
    },

    getRowDetail() {
      let fetchUrl = this.api.collection.getCollectionWarehouseOrderById
      if (this.switch == 1) {
        fetchUrl = this.api.collection.getPendingInbountInfo
      }
      this.$axios(fetchUrl + this.id, {}, 'get').then(data => {
        if (data.status) {
          this.inputForm = data.data
          this.inputForm.reason = this.inputForm.reason + ''
          this.generalList = data.data.collectionGeneralOrderItems
          this.generalList2 = data.data.collectionGeneralOrderItems
          this.auxiliaryList = data.data.collectionHelpOrderItems
          this.auxiliaryList2 = data.data.collectionHelpOrderItems
          this.selectTab = '99'
          let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
          this.inputForm.applicant = userInfo.id
          this.inputForm.applicantName = userInfo.name
        }
      })
    },

    getLeaveList(leave) {
      let data = this.leaveList.filter(item => {
        return item.level == leave
      })
      if (data.length) {
        return data[0].levelName
      }
    },

    getTypeList(type) {
      let data = this.typeList.filter(item => {
        return item.id == type
      })
      if (data.length) {
        return data[0].collectionTypeName
      }
    },

    refresh() {
      this.getRowDetail()
    },

    //0下一步 1上一步
    nextStep(num) {
      if (num == 0) {
        if (this.step == 0) {
          this.$refs.inputForm.validate((valid) => {
            if (valid) {
              this.step++;
            }
          })
        } else {
          if (this.step == 1) {
            if (this.butType == 3) {
              if (this.selectGeneralList.length + this.selectAuxiliaryList.length == 0) {
                this.$message.error('请勾选藏品后再进行预览操作')
                return
              }
              this.generalList2 = this.generalList
              this.auxiliaryList2 = this.auxiliaryList
              this.generalList = this.selectGeneralList
              this.auxiliaryList = this.selectAuxiliaryList
            } else {
              if (this.generalList.length + this.auxiliaryList.length == 0) {
                this.$message.error('请至少选择一件藏品')
                return
              }
            }
            this.step++;
            this.inputType = true
            this.inputType2 = true
          }
        }
      } else {
        if (this.step == 2) {
          this.inputType = false
          this.inputType2 = false
          if (this.butType != 0) {
            this.generalList = this.generalList2
            this.auxiliaryList = this.auxiliaryList2
          }
        }
        this.step--;
      }
    },

    //返回
    returnPage(num) {
      this.returnPageStatus = num
      this.$router.back()
    },

    handleClick(tab, event) {
      if (this.selectTab == 99) {
        this.generalList = this.generalList2
        this.auxiliaryList = this.auxiliaryList2
      } else {
        this.generalList = this.generalList2.filter(item => {
          return item.storeState == this.selectTab
        })
        this.auxiliaryList = this.auxiliaryList2.filter(item => {
          return item.storeState == this.selectTab
        })
      }
    },

    //列表展示
    getlistSelet(id, data, idName, name) {
      let seleRow = data.filter(item => {
        return item[idName] == id
      })
      if (seleRow.length != 0) {
        return seleRow[0][name]
      }
    },

    // type 1提交  0保存为草稿
    saveAsDraft(type) {
      let data = JSON.parse(JSON.stringify(this.inputForm))
      if (this.butType == 3) {
        data.id = null
      }
      let dataALlList = [
        ...this.generalList,
        ...this.auxiliaryList,
      ]
      if (this.butType == 3) {
        dataALlList = [
          ...this.selectGeneralList,
          ...this.selectAuxiliaryList,
        ]
      }
      this.$axios(this.api.collection.collectionwarehouseorder, {
        ...data,
        orderType: 0,
        collectionWarehouseOrderItems: dataALlList,
      }, 'post').then(data => {
        if (data.status) {
          this.$message.success(`${type == 0 ? '保存' : '提交'}成功！`)
          this.programExit = true
          this.returnPage()
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    //type 2驳回 3通过
    through(type) {
      this.$refs.suggestions.init(2, [this.inputForm], type, '', this.api.collection.collectionwarehouseorderRevd, '', '', 'id')
    },

    //1为上一条 0为下一条
    switchData(num) {
      if (Object.keys(this.registrationSearch).length == 0) {
        this.registrationSearch = JSON.parse(sessionStorage.getItem('registrationSearch'))
      }
      if (num == 0) {
        this.registrationSearch.current++
      } else {
        if (this.registrationSearch.current == 1) {
          this.$message.error('已是第一条')
          return
        } else {
          this.registrationSearch.current--
        }
      }
      let fetchUrl = this.api.collection.listOrder
      let methodsType = 'post'
      if (this.switch == 1) {
        fetchUrl = this.api.collection.pendingHandle
        methodsType = 'get'
      }
      if (this.switch == 3) {
        fetchUrl = this.api.collection.listOrderRecode
        methodsType = 'post'
      }
      if (this.switch == 4) {
        fetchUrl = this.api.collection.collectionwarehouseorderHandled
        methodsType = 'get'
      }

      this.$axios(fetchUrl, this.registrationSearch, methodsType).then((res) => {
        if (res.status) {
          if (res.data && res.data.records.length) {
            this.id = res.data.records[0].id
            this.getRowDetail()
          } else {
            this.registrationSearch.current--
            this.$message.error(`已是${num == 1 ? '第一' : '最后'}一条`);
          }
        } else {
          this.registrationSearch.current--
          this.$message.error(`已是${num == 1 ? '第一' : '最后'}一条`);
        }
      })
    },

    //一键入库
    warehousingApplication() {
      this.butType = 3
      this.inputType = false
      this.inputType2 = true
      this.getRowDetail()
      this.setPageTitle()
    },

    // timeComparison() {
    //     if (this.inputForm.operationDate && this.inputForm.preDate) {
    //         if (this.toDateSize(this.inputForm.operationDate, this.inputForm.preDate)) {
    //             this.$message.error('预入库时间不能小于库时间！')
    //             return false
    //         } else {
    //             return true
    //         }
    //     }
    // },

    //时间大小比较
    toDateSize(date1, date2) {
      date1 = new Date(date1).getTime()
      date2 = new Date(date2).getTime()
      if (date1 > date2) {
        return true
      } else {
        return false
      }
    },

    setPageTitle() {
      let name = '入库申请详情'
      if (this.butType == 0) {
        name = '新增入库申请'
      } else if (this.butType == 2) {
        name = '我申请的修改'
      } else if (this.butType == 3) {
        name = '一键入库申请'
      }
      this.$store.state.tagsView.visitedViews.forEach(item => {
        if (item.fullPath === this.$route.fullPath) {
          item.title = name
          return
        }
      })
    },

    // 设置当前行是否禁用【可以选择】 参数row 传入当前行数据
    canBeSelect(row) {
      if (!row.collectionData.cStoreState == 1) {
        return true; // 返回true 可以选择
      } else {
        return false; // 返回false 禁止选择
      }
    },
  },

  //离开此页面
  beforeRouteLeave(to, from, next) {
    // 在这里编写逻辑来决定是否允许离开
    // 如果允许离开，调用 next() 方法
    // 如果不允许离开，调用 next(false) 方法
    if (!this.returnPageStatus) {
      sessionStorage.removeItem('listSearch')
    }
    if (this.programExit) {
      this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {
      })
      next();
    } else if (this.butType == 0 || this.butType == 2) {
      this.$confirm("当前内容没有保存，您确定离开吗？", "提示", {
        confirmButtonText: "退出",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            //进去别的页面
            if (this.returnPageStatus) {
              this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {
              })
            }
            next();
          })
          .catch(() => {
            next(false);
          });
    } else {
      next();
    }
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

.minTitle {
  font-weight: bold;
  font-size: 14px;
  margin-top: 20px;
}

.input-new-tag {
  width: 200px;
}

.input-new-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
}

.adsfaads {
  margin-bottom: 15px !important;
}

/*去掉tabs底部的下划线*/
.tabsCls >>> .el-tabs__nav-wrap::after {
  position: static !important;
}

.pageHeight {
  height: calc(100vh - 205px);
  overflow-y: auto;
}
</style>